<template>
    <div class="referral-history">
        <v-row no-gutters v-if="$vuetify.breakpoint.mdAndUp">
            <v-col cols="12">
                <v-data-table light
                :headers="headers"
                :items="filteredReports"
                class="elevation-0"
                :hide-default-footer="true"
                hide-default-header
                :items-per-page="-1"
                >
                 <template v-slot:header="{ props }">
                <tr>
                    <th class="referral-table-header text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" v-for="head in props.headers" :key="head.text">
                       <span v-if="head.text !=='dateSearch'">  {{$t(`label.${head.text}`)}}
                        <span v-if="head.sortable" >
                            <v-icon v-if="head.sortBy == 'asc'">arrow_drop_down</v-icon>
                            <v-icon v-if="head.sortBy == 'desc'">arrow_drop_up</v-icon>
                        </span>
                       </span>
                    </th>
                    <th class="referral-table-header text-end" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px">
                         <v-menu ref="menu" v-model="dateOfBirthMenu" :close-on-content-click="false" offset-y transition="scale-transition" max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="dateRangeText" clearable @click:clear="clearFilter()" outlined class="date-search ml-3 elevation-0" hide-details="true" v-on="on" append-icon="search"></v-text-field>
                            </template>
                                <v-date-picker @change="filterListByDate()" color="buttonPrimary" no-title v-model="dates" @input="selectedDate()" range></v-date-picker>
                        </v-menu>
                    </th>
                </tr>
                </template>
                <template v-slot:body="{ items }">
                <tr v-for="(item, index) in items" :key="index">
                    <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" >
                       {{ item.updated_at | longDate  }}
                    </td>
                    <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" >
                       {{ item.downline_membercode  }}
                    </td>
                    <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" >
                       {{ item.commission_amount |currency(currentCurrency) }}
                    </td>
                    <td class="text-start text-capitalize" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" >
                        <v-icon color="red" class="mb-1" small v-if="item.status == 'failed'">cancel</v-icon>
                        <v-icon color="green" class="mb-1" small v-if="item.status == 'success'">check_circle</v-icon>
                        <v-icon color="yellow" class="mb-1" small v-if="item.status == 'pending'">watch_later</v-icon>
                       {{ item.status }}
                    </td>
                    <td class="text-start" style="border-bottom: thin solid rgba(0,0,0,.12);padding: 10px 16px" >
                    </td>
                </tr>
                </template>
                </v-data-table>
            </v-col>
        </v-row>
         <v-row no-gutters v-else>
             <v-col cols="12">
                <v-menu ref="menu" v-model="dateOfBirthMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field v-model="dateRangeText" clearable @click:clear="clearFilter()" color="buttonPrimary" outlined class="date-search-mobile mb-3 elevation-0" hide-details="true" v-on="on" append-icon="search"></v-text-field>
                    </template>
                    <v-date-picker color="buttonPrimary" @change="filterListByDate()"  no-title v-model="dates" range></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12">
                <v-row v-for="(item, index) in filteredReports" :key="index">
                    <v-col cols=2>
                        <v-icon color="red" class="mt-2" v-if="item.status == 'failed'">cancel</v-icon>
                        <v-icon color="green" class="mt-2" v-if="item.status == 'success'">check_circle</v-icon>
                        <v-icon color="yellow" class="mt-2" v-if="item.status == 'pending'">watch_later</v-icon>
                    </v-col>
                     <v-col cols=4>
                         <v-row no-gutters class="referral-table-header">
                             <v-col cols=12>
                                 {{ $t(`label.date`) }}
                             </v-col>
                             <v-col cols=12>
                                 {{ $t(`label.commission`) }}
                             </v-col>
                         </v-row>
                     </v-col>
                     <v-col cols=6>
                         <v-row no-gutters>
                             <v-col cols=12>
                                 {{ item.created_at | longDate }}
                             </v-col>
                             <v-col cols=12>
                                 {{ item.commission_amount |currency(currentCurrency) }}
                             </v-col>
                         </v-row>
                     </v-col>
                </v-row>
            </v-col>
         </v-row>
          <v-row>
            <v-col cols="12" v-if="filteredReports.length == 0" class="text-center pt-0 pb-8" >
                <label class="referral-table-header subtitle-2">{{$t(`message.noDataAvaliable`)}}</label>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {
        ROUTE_NAME
    } from '@/constants/route.constants'
    import {
        uiHelper,
        locale,
        errorCodeHelper,
        formValidator
    } from '@/util'
    import {
        SHARED
    } from '@/constants/constants'
    import deleteIcon from '@/assets/svg/delete-icon.svg'
    import _ from 'lodash';
    import {
        REFERRAL_GET_REPORT
    } from "@/store/referral.module";
    export default {
        name: 'referralHistory',
        components: {
            deleteIcon
        },
        data: () => ({
            dateOfBirthMenu: false,
            currentCurrency: uiHelper.getCurrency(),
            language: uiHelper.getLanguage(),
            validator: formValidator,
            shared: SHARED,
            selectedTab: 'tier',
            dates: ['', ''],
            headers: [{
                    text: 'date',
                    align: 'start',
                    sortable: false,
                    value: 'member_id',
                },
                {
                    text: 'memberCode',
                    value: 'membercode',
                    sortable: false,
                },
                {
                    text: 'commission',
                    value: 'commission',
                    sortable: false,
                },
                {
                    text: 'status',
                    value: 'status',
                    sortable: false,
                }
            ],
            reports: [],
            filteredReports: []
        }),
        created() {
            this.getReferralReports()
        },
        computed: {
             dateRangeText: {
                get(){
                    let startDate = this.dates[0] ? this.dates[0] : ''
                    let endDate = this.dates[1] ? this.dates[1] : ''
                    return startDate  + ' - ' + endDate
                },
                set(dateRange){
                    this.dates = []
                } 
            },
            isLoggedIn() {
                return this.$store.state.member.isLoggedIn
            },
            referralReports() {
                return this.$store.state.referral.reports
            }
        },
        watch: {
            referralReports() {
                this.reports = this.$store.state.referral.reports
                this.filterListByDate()
            }
        },
        methods: {
            selectedDate() {
                if(this.dates[0] && this.dates[1]){
                    setTimeout(function () {
                        this.dateOfBirthMenu = false
                    }, 500);
                }
            },
            openMsgDialog(selectedMsg) {
                this.msgDialog = {
                    show: true,
                    msg: selectedMsg.message,
                    title: selectedMsg.subject
                }
            },
            closeMsgDialog() {
                this.msgDialog = {
                    show: false
                }
            },
            getReferralReports() {
                if (this.isLoggedIn)
                    this.$store.dispatch(`${REFERRAL_GET_REPORT}`)
            },
            filterListByDate () {
                if(this.dates[0] && this.dates[1]){
                    var startDate = new Date(this.dates[0]);
                    var endDate = new Date(this.dates[1]);
                    this.filteredReports = this.reports.filter(function(r) { 
                        let date = new Date(r.created_at)
                        return date >= startDate && date <= endDate 
                    });
                } else {
                    return this.filteredReports =  _.cloneDeep(this.reports)
                }
                
            },
            clearFilter () {
                this.dates = []
                this.filterListByDate()
            }
        }
    }
</script>

<style lang="scss">
.referral-history{
    .date-search{
        width: fit-content;
        margin-right: 0px;
        float: right !important;
        .v-input__slot {
            min-height: 40px !important;
            max-width: 250px;
        }
        .v-input__append-inner {
            margin-top: 8px;
        }
    }
    .referral-table-header{
        color: #ACACAC;
    }
}

.date-search-mobile {
        .v-input__slot {
            min-height: 40px !important;
        }
        .v-input__append-inner {
            margin-top: 8px;
        }
    }

</style>